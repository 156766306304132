@import url("https://p.typekit.net/p.css?s=1&k=rzd1hal&ht=tk&f=255.1273.1276.1277.1278.1279.1280.1281.1282.1283.1284.1285&a=100649833&app=typekit&e=css");

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/0f82c8/00000000000000007735961f/30/l?subset_id=2&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/0f82c8/00000000000000007735961f/30/d?subset_id=2&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/0f82c8/00000000000000007735961f/30/a?subset_id=2&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/e9bdf9/000000000000000077359620/30/l?subset_id=2&fvd=i5&v=3") format("woff2"), url("https://use.typekit.net/af/e9bdf9/000000000000000077359620/30/d?subset_id=2&fvd=i5&v=3") format("woff"), url("https://use.typekit.net/af/e9bdf9/000000000000000077359620/30/a?subset_id=2&fvd=i5&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/2f6b90/000000000000000077359625/30/l?subset_id=2&fvd=n2&v=3") format("woff2"), url("https://use.typekit.net/af/2f6b90/000000000000000077359625/30/d?subset_id=2&fvd=n2&v=3") format("woff"), url("https://use.typekit.net/af/2f6b90/000000000000000077359625/30/a?subset_id=2&fvd=n2&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
}

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/38ee52/000000000000000077359626/30/l?subset_id=2&fvd=i2&v=3") format("woff2"), url("https://use.typekit.net/af/38ee52/000000000000000077359626/30/d?subset_id=2&fvd=i2&v=3") format("woff"), url("https://use.typekit.net/af/38ee52/000000000000000077359626/30/a?subset_id=2&fvd=i2&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 200;
  font-stretch: normal;
}

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/3ba9ff/000000000000000077359627/30/l?subset_id=2&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/3ba9ff/000000000000000077359627/30/d?subset_id=2&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/3ba9ff/000000000000000077359627/30/a?subset_id=2&fvd=n3&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/f83f22/000000000000000077359628/30/l?subset_id=2&fvd=i3&v=3") format("woff2"), url("https://use.typekit.net/af/f83f22/000000000000000077359628/30/d?subset_id=2&fvd=i3&v=3") format("woff"), url("https://use.typekit.net/af/f83f22/000000000000000077359628/30/a?subset_id=2&fvd=i3&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/4c997e/000000000000000077359629/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/4c997e/000000000000000077359629/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/4c997e/000000000000000077359629/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/da5775/00000000000000007735962a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/da5775/00000000000000007735962a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/da5775/00000000000000007735962a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/a2456e/00000000000000007735962b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/a2456e/00000000000000007735962b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/a2456e/00000000000000007735962b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/0afbfd/00000000000000007735962c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/0afbfd/00000000000000007735962c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/0afbfd/00000000000000007735962c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/0e2b9f/00000000000000007735962d/30/l?subset_id=2&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/0e2b9f/00000000000000007735962d/30/d?subset_id=2&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/0e2b9f/00000000000000007735962d/30/a?subset_id=2&fvd=n9&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: "sui-generis";
  src: url("https://use.typekit.net/af/34ad61/00000000000000007735962e/30/l?subset_id=2&fvd=i9&v=3") format("woff2"), url("https://use.typekit.net/af/34ad61/00000000000000007735962e/30/d?subset_id=2&fvd=i9&v=3") format("woff"), url("https://use.typekit.net/af/34ad61/00000000000000007735962e/30/a?subset_id=2&fvd=i9&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: 'sui-generis', sans-serif;
}

input {
  //color-scheme: dark;
}

input, textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  background-color: rgba(0, 181, 55, 0.25) !important;
  font-style: italic;
}

input::placeholder {
  color: $primaryColorLightX2;
}

input {
  width: 100%;
  padding: 2vw 15px;
  border: 0.4vw solid transparent;
  border-radius: 0;
  background-color: rgba(0, 181, 55, 0.25) !important;
  font-size: 4vw !important;
  color: #fff;
  outline: 0;
  z-index: 6;
  position: relative;
  //border: 0;

  &:-webkit-autofill::first-line {
    font-size: 4vw;
    font-weight: 300;
  }
}

.is-invalid {
  background-color: rgba(254, 0, 0, 0.25) !important;
  box-shadow: inset 0 0 3vw #FE0000, 0 0 3vw #FE0000;
}

.is-valid {
  background-color: rgba(0, 181, 55, 0.25) !important;
  box-shadow: inset 0 0 3vw $primaryColor, 0 0 3vw $primaryColor;
}

.mt-7w {
  margin-top: 7vw;
}

.mb-3w {
  margin-bottom: 3vw;
}

.mb-2w {
  margin-bottom: 2vw;
}

.mt-3w {
  margin-top: 3vw;
}

.mt-40w {
  margin-top: 40vw;
}

//input:-internal-autofill-previewed {
//  font-size: 4vw !important;
//}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #fff !important;
  transition: background-color 5000s ease-in-out 0s;
  font-size: 4vw !important;
}

input:-webkit-autofill::first-line {
  color: #fff !important;
  font-size: 4vw !important;
}

.default-text {
  font-size: 2.4vw;
  color: #fff;
  font-weight: 300;
}

.mt-22 {
  margin-top: 22vw;
}

.form-check .form-check-input {
  margin-left: -1.5vw;
  float: none;
  box-shadow: inset 0 0 2vw #00b537, 0 0 2vw #00b537;
  border-radius: 0;
}

.form-check .form-check-input.is-invalid {
  box-shadow: inset 0 0 2vw $red, 0 0 2vw $red;
}

.form-check-input:disabled,
.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  opacity: 1;
  pointer-events: initial;
}

.w-80 {
  width: 80%;
}

.bottom--20 {
  bottom: -20vh !important;
}

.font-weight-500 {
  font-weight: 500;
}

.font-17 {
  font-size: 1.7vw;
}

.font-26 {
  font-size: 2.6vw;
}

.w-80 {
  width: 80%;
}

.w-65 {
  width: 65%;
}

.top--11 {
  top: -28px;
}

.top-10 {
  top: 10px;
}

.top-30 {
  top: 30px;
}

.bg-red {
  background-color: $red !important;
}

.progress-100.progress-sc:after {
  width: 100%;
}

.progress-50.progress-sc:after {
  width: 50%;
}

.sc-green {
  box-shadow: inset 0 0 6px 2px $primaryColor, 0 0 12px 3px $primaryColor, 0 0 12px 3px $primaryColor;
}

.sc-green:after {
  background: $primaryColor;
}

.sc-red {
  box-shadow: inset 0 0 6px 2px $red, 0 0 12px 3px $red, 0 0 12px 3px $red;
}

.sc-red:after {
  background: $red;
}

.sc-purple {
  box-shadow: inset 0 0 6px 2px $secondaryColorDark, 0 0 12px 3px $secondaryColorDark, 0 0 12px 3px $secondaryColorDark;
}

.sc-purple:after {
  background: $secondaryColorDark;
}

.sc-violet {
  box-shadow: inset 0 0 6px 2px $secondaryColorDark, 0 0 12px 3px $secondaryColorDark, 0 0 12px 3px $secondaryColorDark;
}

.sc-violet:after {
  background: $secondaryColorDark;
}

.sc-yellow {
  box-shadow: inset 0 0 6px 2px $yellow, 0 0 12px 3px $yellow, 0 0 12px 3px $yellow;
}

.sc-yellow:after {
  background: $yellow;
}

.sc-orange {
  box-shadow: inset 0 0 6px 2px $yellow, 0 0 12px 3px $yellow, 0 0 12px 3px $yellow;
}

.sc-orange:after {
  background: $yellow;
}

.sc-blue {
  box-shadow: inset 0 0 6px 2px $blue, 0 0 12px 3px $blue, 0 0 12px 3px $blue;
}

.sc-blue:after {
  background: $blue;
}

.font-15 {
  font-size: 1.5vw !important;
}

.text-left {
  text-align: left !important;
}

.font-weight-600 {
  font-weight: 600;
}

.mt--20 {
  margin-top: -20px;
}

.mb--20 {
  margin-bottom: -20px;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font2-5 {
  font-size: 2.5vw;
}

.border-top {
  width: 100%;
  display: block;
  height: 2px;
  z-index: 11;
  background-color: #fff;
  position: absolute;
  filter: blur(0.7px);
  top: 0px;
}

.border-bottom {
  width: 100%;
  display: block;
  height: 2px;
  z-index: 11;
  background-color: #fff;
  position: absolute;
  filter: blur(0.7px);
  bottom: 25px;
}

.border-left {
  width: 2px;
  height: calc(100% - 26px);
  background-color: #fff;
  position: absolute;
  filter: blur(0.7px);
  z-index: 11;
  left: 0;
}

.border-right {
  width: 2px;
  height: calc(100% - 26px);
  background-color: #fff;
  position: absolute;
  filter: blur(0.7px);
  z-index: 11;
  right: 0;
}

.font2-0 {
  font-size: 1.5vw;
}

//.font2-0 {
//  font-size: 1.8vw;
//}

.font-35 {
  font-size: 37px;
}

.font-45 {
  font-size: 40px;
}

.p4 {
  padding: 30px;
}

.padd-25 {
  padding: 25px;
}



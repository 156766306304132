.slider-level {
  transform: skew(-15deg);
  background-color: rgba(210, 48, 242, 0.4);
  padding: 34px 14px;
  font-size: 1vw;
  border: 2px solid $secondaryColorLightX2;
  border-radius: 6px;

  .horizontal-slider {
    background-color: rgba(210, 48, 242, 1);
    height: 8px;

    .example-thumb.example-thumb-0 {
      top: -21px;
      width: 48px;
      height: 52px;
      color: gray;
      background: radial-gradient(#fff, gray);
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid #fff;

      &:focus-visible,
      &:focus {
        outline: 0;
      }
    }

    .example-track.example-track-1 {
      background-color: gray;
      height: 8px;
    }

    .example-mark {
      width: 44px;
      height: 42px;
      background-color: rgba(210, 48, 242, 1);
      top: -16px;
      border-radius: 4px;
      font-size: 28px;
      text-align: center;
      justify-content: center;
      display: flex;
      padding-left: 0;
      padding-top: 1px;
      padding-right: 5px;
      cursor: pointer;
    }
  }
}

.time-counter {
  color: #fff;
  font-size: 7vw;
  font-style: italic;
  font-weight: 600;
}

.full-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media screen and (max-width: 1400px) {
  .form-settings{
    height: 8.5vw!important;
  }
  .label-settings{
    font-size: 3.5vw!important;
  }
  .settings-container h1{
    font-size: 9.5vw!important;
  }
}
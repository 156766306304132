.main-button {
  border: 3px solid #fff;
  border-radius: 0;
  color: #fff;
  padding: 4px 2vw;
  font-weight: 700;
  font-style: italic;
  font-size: 6vw;
}

.error-btn {
  padding: 20px 2vw;
  font-size: 6vw;
  line-height: 7vw;
  background-color: $redDark;
  box-shadow: $shadowInsetRed;
}

.success-btn {
  background-color: $primaryColorDark;
  box-shadow: $shadowInset;
}

.btn-play {
  background-color: rgba(210, 48, 242, 0.6);
  color: #fff;
  padding: 5px 30px;
  display: inline-block;
  border: 3px solid $secondaryColorLightX2;
  box-shadow: inset 0 0 9px 3px rgba(210, 48, 242, 1), 0 0 16px 4px rgba(210, 48, 242, 1);
  border-radius: 0;
  transform: skew(-15deg);
  font-style: normal;
}

.btn-play:active{
  color: var(--bs-link-hover-color);
}

.button-back {
  position: absolute;
  left: -10vw;
  top: -120px;
  width: 15vw;
}

.screen-container {
  width: 100%;
  height: 56.25vw;
  background: url("../img/Selection_screen_Bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 3%;
}

.bg-screen-red {
  background: url("../img/Background_red.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-screen-green {
  background: url("../img/Background_green.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.screen-container {
  .container {
    max-width: 100%;
    padding: 0 2vw;
  }

  .score {
    color: #ffffff;
    font-size: 1.5vw;
    font-style: italic;
    text-align: center;
    font-weight: 400;

    table {
      width: -webkit-fill-available;
      //border-collapse: separate;
      tr:first-of-type {
        line-height: 1.7vw;
      }

      border-collapse: separate;
      border-spacing: 0 7px;
    }
  }
}

.arrow-game {
  position: absolute;
  width: 7vw;
  top: 0;
  right: -1vw;
  overflow: hidden;

}

.arrow-game:first-of-type {
  top: -7vw;
}

.arrow-game:last-of-type {
  top: 15vw;
}

.arr-coop.coop-right {
  right: -2vw;
}

.arr-coop.coop-left {
  left: -2vw;
}

.arr-coop {
  position: absolute;
  width: 13vw;
  top: -10px;
}

.text-c1 {
  font-size: 1.7vw;
  color: #ffffff;
  font-style: italic;
}

.score-red {
  .hide-red-score {
    display: none;
  }

  .div-btn-reset {
    min-height: 12vw;
    display: flex;
    align-items: center;
  }

  .score {
    background-color: rgba(254, 0, 0, 0.30);
  }

  .second-border {
    border-color: $redLightX2;
    box-shadow: inset 0 0 6px 2px $red, 0 0 12px 3px $red;
  }

  .main-border {
    border-color: $redLightX2;
    box-shadow: inset 0 0 12px 4px $red, 0 0 10px 6px $red;
  }

  .tr-table {
    background-color: rgba(254, 0, 0, 0.1);

    td {
      box-shadow: inset 0 0 6px 2px $red, 0 0 12px 3px $red;
      border-color: $redLightX2;
    }
  }

  .bg-light1 {
    background-color: rgba(254, 0, 0, 0.30);
  }

  .active-selection {
    background-color: rgba(254, 0, 0, 0.8);
  }
}

.score-green {
  .score {
    background-color: rgba(0, 181, 55, 0.30);
  }

  .second-border {
    border: 3px solid $primaryColorLightX2;
    box-shadow: inset 0 0 6px 2px #00b537, 0 0 12px 3px #00b537;
    border-radius: 0;
  }

  .main-border {
    border: 3px solid $primaryColorLightX2;
    border-radius: 0;
    box-shadow: inset 0 0 12px 4px #00b537, 0 0 10px 6px #00b537;
  }

  .tr-table {
    height: 3.3vw;
    background-color: rgba(0, 181, 55, 0.30);

    td {
      box-shadow: inset 0 0 6px 2px #00b537, 0 0 12px 3px #00b537;
      border: 3px solid $primaryColorLightX2;
      border-radius: 0;
    }
  }

  .bg-light1 {
    background-color: rgba(0, 181, 55, 0.30);
  }

  .active-selection {
    background-color: rgba(0, 181, 55, 0.8);
  }
}

.screen-container {
  svg {
    overflow: inherit;
  }

  .green-template {
    svg path:first-of-type {
      filter: drop-shadow(0px 0px 1px $primaryColorLight) drop-shadow(0px 0px 1px $primaryColorLight);
    }

    svg path:last-of-type {
      // filter: drop-shadow(0px 0px 6px $primaryColorLight) drop-shadow(0px 0px 6px $primaryColorLight) drop-shadow(0px 0px 6px $primaryColorLight) drop-shadow(0px 0px 6px $primaryColorLight) drop-shadow(0px 0px 6px $primaryColor) drop-shadow(0px 0px 6px $primaryColor) drop-shadow(0px 0px 6px $primaryColor) drop-shadow(0px 0px 6px $primaryColor) drop-shadow(0px 0px 6px $primaryColor)
    }

    .text-counter {
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: $primaryColorLight;
      text-shadow: 5px 8px 12px #000,
      5px 8px 12px $primaryColorDarkX2,
      5px 5px 10px $primaryColorDarkX2;
    }
  }

  .red-template {
    svg path:first-of-type {
      filter: drop-shadow(0px 0px 1px $red) drop-shadow(0px 0px 1px $red);
    }

    svg path:last-of-type {
      // filter: drop-shadow(0px 0px 6px $redLight) drop-shadow(0px 0px 6px $redLight) drop-shadow(0px 0px 6px $redLight) drop-shadow(0px 0px 6px $redLight) drop-shadow(0px 0px 6px $red) drop-shadow(0px 0px 6px $red) drop-shadow(0px 0px 6px $red) drop-shadow(0px 0px 6px $red) drop-shadow(0px 0px 6px $red)
    }

    .text-counter {
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: $red;
      text-shadow: 5px 8px 12px $redDark,
      5px 8px 12px $redDark,
      5px 5px 10px #000;
    }
  }

}

.div-score {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.div-progress {
  width: 100%;
  border-radius: 0;
  height: auto;
  overflow: initial;
  transform: skew(-15deg);
  background-color: transparent;
  border: 2px solid #fff;
}

.div-progress .progress-bar,
.div-sc {
  color: #fff;
  text-align: center;
  padding: 8px;
  font-weight: 500;
  font-size: 1.5vw;
  letter-spacing: 3px;
}

.div-sc {
  width: 100%;
  transform: skew(-15deg);
  border: 4px solid #fff;
}

.div-progress.sc-green .progress-bar {
  background-color: $primaryColor;
}

.div-progress.sc-red .progress-bar {
  background-color: $red;
}

.div-progress.sc-orange .progress-bar {
  background-color: $yellow;;
}

.div-progress.sc-blue .progress-bar {
  background-color: $blue;
}

.div-progress.sc-purple .progress-bar {
  background-color: $secondaryColorDark;
}

.div-progress.sc-violet .progress-bar {
  background-color: $secondaryColorDark;
}

.div-progress .progress-bar {
  font-size: 2.3vw;
  height: 75px;
}

.progress-label {
  width: 100%;
  position: absolute;
}

.progress-sc:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.row-heart {
  margin-top: -2vw;
  margin-bottom: 2vw;

  div {
    display: flex;
    justify-content: space-between;
  }

  img {
    width: 10vw;
  }
}

.text-team {
  color: #fff;
  font-size: 1.9vw;
  font-weight: 600;
  font-style: italic;
  //text-align: center;
  text-transform: uppercase;
  margin-bottom: -4vw !important;
  text-align: left;

  span {
    font-weight: 300;
    font-size: 1.1vw;
    display: block;
    position: relative;
    bottom: -5px;
  }
}

.div-cerc {
  display: flex;
  justify-content: center;
}

.cerc {
  width: 4vw;
  height: 4vw;
  border-radius: 100%;
  background-color: gray;
  margin: 0 10px;

  &.sc-red {
    border: 2px solid #fff;
    background-color: $red;
  }

  &.sc-purple {
    border: 2px solid #fff;
    background-color: $secondaryColorDark;
  }

  &.sc-violet {
    border: 2px solid #fff;
    background-color: $secondaryColorDark;
  }

  &.sc-yellow {
    border: 2px solid #fff;
    background-color: $yellow;
  }

  &.sc-orange {
    border: 2px solid #fff;
    background-color: $yellow;
  }

  &.sc-blue {
    border: 2px solid #fff;
    background-color: $blue;
  }

  &.sc-green {
    border: 2px solid #fff;
    background-color: $primaryColor;
  }
}

.text-tv {
  color: #fff;
  font-style: italic;
  font-weight: 700;
  line-height: 3vw;
}

.skew-0 {
  transform: skew(0);
}

.div-question {
  width: 100%;

  .div-sc {
    box-shadow: inset 0 0 6px 2px $primaryColor, 0 0 12px 3px $primaryColor;
    font-style: italic;
    font-size: 1.5vw;
    padding: 8px;

    &:last-of-type {
      //border-bottom: 0;
    }
  }
}

.div-mini-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  p {
    color: #fff;
    font-size: 3vw;
    font-style: italic;
    font-weight: 700;
  }
}

.mini-slider-green {
  svg path:first-of-type {
    filter: drop-shadow(0px 0px 3px #00E646) drop-shadow(0px 0px 3px #00E646)
  }

  svg path:last-of-type {
    filter: drop-shadow(0px 0px 8px #00E646) drop-shadow(0px 0px 8px #00E646) !important;
  }
}

.mini-slider-red {
  svg path:first-of-type {
    filter: drop-shadow(0px 0px 2px $red) drop-shadow(0px 0px 2px $red) !important;
  }

  svg path:last-of-type {
    filter: drop-shadow(0px 0px 2px $redLight) drop-shadow(0px 0px 2px $redLight) drop-shadow(0px 0px 2px $redLight) drop-shadow(0px 0px 3px $redLight) drop-shadow(0px 0px 3px $redLight) drop-shadow(0px 0px 3px $red) drop-shadow(0px 0px 1px $red) drop-shadow(0px 0px 1px $red) !important;
  }
}

.mini-slider-blue {
  svg path:first-of-type {
    filter: drop-shadow(0px 0px 2px $blue) drop-shadow(0px 0px 2px $blue) !important;
  }

  svg path:last-of-type {
    filter: drop-shadow(0px 0px 2px $blue) drop-shadow(0px 0px 2px $blue) drop-shadow(0px 0px 2px $blue) drop-shadow(0px 0px 3px $blue) drop-shadow(0px 0px 3px $blue) drop-shadow(0px 0px 3px $blue) drop-shadow(0px 0px 1px $blue) drop-shadow(0px 0px 1px $blue) !important;
  }
}

.mini-slider-yellow {
  svg path:first-of-type {
    filter: drop-shadow(0px 0px 2px $yellow) drop-shadow(0px 0px 2px $yellow) !important;
  }

  svg path:last-of-type {
    filter: drop-shadow(0px 0px 2px $yellow) drop-shadow(0px 0px 2px $yellow) drop-shadow(0px 0px 2px $yellow) drop-shadow(0px 0px 3px $yellow) drop-shadow(0px 0px 3px $yellow) drop-shadow(0px 0px 3px $yellow) drop-shadow(0px 0px 1px $yellow) drop-shadow(0px 0px 1px $yellow) !important;
  }
}

.mini-slider-purple {
  svg path:first-of-type {
    filter: drop-shadow(0px 0px 2px $secondaryColor) drop-shadow(0px 0px 2px $secondaryColor) !important;
  }

  svg path:last-of-type {
    filter: drop-shadow(0px 0px 2px $secondaryColor) drop-shadow(0px 0px 2px $secondaryColor) drop-shadow(0px 0px 2px $secondaryColor) drop-shadow(0px 0px 3px $secondaryColor) drop-shadow(0px 0px 3px $secondaryColor) drop-shadow(0px 0px 3px $secondaryColor) drop-shadow(0px 0px 1px $secondaryColor) drop-shadow(0px 0px 1px $secondaryColor) !important;
  }
}

.row-top-score {
  width: 85vw;
  margin: auto;

  .col-md-8,
  .col-md-3,
  .col-md-1 {
    background: linear-gradient($primaryColor, transparent);
    padding-top: 20px;
  }

  .col-md-8 {
    width: 62.666667%;
    margin: 0 2%;
    padding-left: 25px;
  }

  .col-md-3 {
    padding-left: 25px;
  }

  p {
    color: #fff;
    font-size: 1.6vw;
    font-weight: 600;
  }

  .col-md-1 {
    text-align: center;
  }
}

.progress-bar-box {
  width: 42%;
  text-align: center;
  margin: 4% auto 4% auto;
}

$primaryColor: #00B537;
$primaryColorLight: #00E646;
$primaryColorLightX2: #CFF3B1;
$primaryColorDark: #197E38;
$primaryColorDarkX2: #0E4820;

$yellow: #fe8f00;
$blue: #0055ff;

$secondaryColor: #CC3DCC;
$secondaryColorLight: #EB7AEB;
$secondaryColorLightX2: #FFC9FF;
$secondaryColorDark: #850085;
$secondaryColorDarkX2: #520052;
$secondaryColorDarkX3: #2F0039;

$red: #FE0000;
$redLight: #FE5959;
$redLightX2: #FFBFBF;
$redDark: #4C0000;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin text-shadow($shadow) {
  text-shadow: $shadow;
}

$sizes: 12;
@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 1vw;
    .m#{$i} {
      margin: $margin;
    }
    .ml#{$i} {
      margin-left: $margin;
    }
    .mr#{$i} {
      margin-right: $margin;
    }
    .mt#{$i} {
      margin-top: $margin;
    }
    .mb#{$i} {
      margin-bottom: $margin;
    }
    .mx#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}

@include margin-classes;

@mixin stroke {
  @for $i from 1 through $sizes {
    $stroke: $i * 1px;
    .stroke#{$i} {
      -webkit-text-stroke-width: $stroke;
      -webkit-text-stroke-color: $primaryColorLight;
    }
  }
}

@include stroke;

@mixin font-classes {
  @for $i from 1 through $sizes {
    $font: $i * 1vw;
    .font#{$i} {
      font-size: $font !important;
    }
  }
}

@include font-classes;

@mixin line-height-classes {
  @for $i from 1 through $sizes {
    $font: $i * 1vw;
    .line-h#{$i} {
      line-height: $font !important;
    }
  }
}

@include line-height-classes;

@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25vw;
    .p#{$i} {
      padding: $padding;
    }
    .pl#{$i} {
      padding-left: $padding;
    }
    .pr#{$i} {
      padding-right: $padding;
    }
    .pt#{$i} {
      padding-top: $padding;
    }
    .pb#{$i} {
      padding-bottom: $padding;
    }
    .px#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .py#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}

@include padding-classes;

$mainShadow: 4px 4px 25px $primaryColor, 4px 4px 25px $primaryColorDarkX2, 4px 4px 25px $primaryColorDarkX2, 4px 4px 25px $primaryColorDarkX2;
$secondShadow: 2px 2px 15px $primaryColor, 4px 4px 15px $primaryColorDarkX2, 4px 4px 10px $primaryColorDarkX2, 5px 5px 20px $primaryColorDarkX2;
$shadowInset: inset 0 0 2vw $primaryColorLight, 0 0 2vw $primaryColorLight;
$shadowInsetRed: inset 0 0 2vw $red, 0 0 2vw $red;


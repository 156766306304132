//Culori principale:
//- #00B537 și valorile mai light: #00E646, CFF3B1 + valorile mai dark: #197E38, #0E4820
//- #CC3DCC și valorile mai light: #EB7AEB, FFC9FF + valorile mai dark: #850085, #520052, #2F0039
//
//Culori secundare:
//- #FE8F00 și valorile mai light: #FEB14E, #FFD299 + valorile mai dark: #B16401, #663900
//
//-#FE0000 și valorile mai light: #FE5959, #FFBFBF + valorile mai dark: #8B0000, #4C0000
//
//- #0055FF și valorile mai light: #548DFF, #BFD4FF + valorile mai dark: #003CB3, #051D4D


.tabletScreen {
  box-sizing: border-box;
  resize: horizontal;
  overflow: auto;
  max-width: 100%;
  height: 100vh;
  background: url("../img/background_2x.webp");
  background-size: cover;
  background-repeat: no-repeat;
  font-style: italic;
  display: flex;
}

.tablet-container {
  width: 100%;
  background: transparent;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;

  .link-tablet {
    display: flex;
    font-style: italic;
    align-items: center;
    justify-content: center;
  }

  h1 {
    text-align: center;
    font-size: 24vw;
    line-height: 24vw;
  }

  .second-font {
    font-size: 15vw;
    line-height: 17vw;
    font-weight: bold;
  }

  .error-text {
    font-size: 16vw;
    font-weight: 700;
  }
}

.main-text {
  color: #fff;
  -webkit-text-stroke-color: $primaryColorLight;
  text-shadow: $mainShadow;
}

.main-container {
  width: 65%;
  margin: auto;
  position: relative;

  h1 {
    font-size: 10vw;
    font-weight: 700;
    margin-bottom: 3vw;
  }

  .main-text {
    text-shadow: $secondShadow;
  }
}

.shadow-text {
  text-shadow: 6px 6px 6px $primaryColorDarkX2, 2px 5px 7px $primaryColorDarkX2, 2px 8px 5px $primaryColorDarkX2;
}

.shadow-text-2 {
  text-shadow: 6px 6px 6px #333, 2px 5px 7px #333, 2px 8px 5px #333;
}

.link {
  text-decoration: underline;
  color: #fff;
  font-size: 4vw;
  display: block;
  font-weight: 300;
}

.p-text {
  color: $primaryColorLightX2;
  font-size: 4vw;
}

.error-message {
  font-size: 2.8vw;
  color: $red;
  font-weight: 300;
  margin-top: 1vw;
  position: absolute;
}

.error-text {
  color: #fff;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: $red;
  text-shadow: 4px 4px 20px $red, 8px 8px 10px $redDark, 8px 8px 15px $redDark, 8px 8px 15px $redDark;
}

.reg-confirm {
  flex-wrap: wrap;

  .main-button {
    text-align: center;
    display: block;
    position: relative;
    bottom: -10vh;
  }
}

.main-modal {
  width: 100%;
  max-width: 100%;
  height: 92vh;
  max-height: 92vh;
  margin: 0;
  overflow: hidden;
  top: 2vh;

  .modal-dialog {
    width: 86%;
    max-width: 86%;
    height: 100vh;
    max-height: 100vh;
  }

  .modal-header {
    border: 0;

    .modal-title {
      font-size: 4vw;
      padding-left: 4vw;
      padding-top: 3vw;
    }
  }

  .modal-content {
    background-color: rgba(25, 126, 56, 0.8);
    border: 3px solid #fff;
    border-radius: 0;
    box-shadow: $shadowInset;
    color: #fff;
    font-style: italic;
    font-size: 3vw;
    padding-right: 6vw;
  }

  .modal-body {
    padding: 0 7vw;
    padding-top: 3vw;
    overflow-y: scroll;
    height: 73vh;
    margin-bottom: 5vw;
    margin-top: 2vw;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 0 0 5px $primaryColorLightX2;
    border-radius: 4px;
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
}

.modal-backdrop.show {
  opacity: 0;
}

.form-group {
  margin-bottom: 3vw;
  position: relative;
}

.log-img {
  width: 45vw;
  position: relative;
}

.w-img {
  width: calc(100% + 5.5vw);
  position: relative;
  left: -2.8vw;
}

.w-img2 {
  position: relative;
  left: -3vw;
  width: 32vw;
}

.mt--50 {
  margin-top: -50px;
}

.blur-bottom {
  bottom: 19px;
}

.blur-top {
  top: -5px;
}

.blur-x {
  width: 100%;
  height: 14px;
  background-color: #00b537;
  position: absolute;
  filter: blur(5px);
  z-index: 5;
}

.blur-y {
  width: 11px;
  height: calc(100% - 24px);
  background-color: #00b537;
  position: absolute;
  filter: blur(5px);
  z-index: 5;
}

.blur-left {
  left: -5px
}

.blur-right {
  right: -5px
}

.left-0 {
  left: 0 !important;
}

.hidden {
  display: none;
}

.zIndex {
  opacity: 0.01;
  //width: 1px;
  //height: 1px;
  //padding: 0;
  //margin: 0;
  //border: 0;
  //font-size: 0 !important;
}

.second-border {
  border: 3px solid $primaryColorLightX2;
  box-shadow: inset 0 0 6px 2px #00b537, 0 0 12px 3px #00b537;
  border-radius: 0;
}

.main-border {
  border: 3px solid $primaryColorLightX2;
  border-radius: 0;
  box-shadow: inset 0 0 12px 4px #00b537, 0 0 24px 6px #00b537;
}

.tr-table {
  height: 3.3vw;
  background-color: rgba(0, 181, 55, 0.30);

  td {
    box-shadow: inset 0 0 6px 2px #00b537, 0 0 12px 3px #00b537;
    border: 3px solid $primaryColorLightX2;
    border-radius: 0;
  }
}

.bg-light1 {
  background-color: rgba(0, 181, 55, 0.30);
}

//.carousel {
//  touch-action: none;
//}


.example-mark-disabled {
  background-color: grey !important;
}

.game-selection-carousel {
  height: 379px;

  .carousel__inner-slide {
    display: flex;
    flex-wrap: wrap;

    // .col-md-6:nth-child(even) {
    //   padding-left: 0.5vw;
    // }

    // .col-md-6:nth-child(odd) {
    //   padding-right: 0.5vw;
    // }

    // .mt1:nth-of-type(1),
    // .mt1:nth-of-type(2) {
    //   margin-top: 0;
    // }
  }

  .carousel__back-button .arrow-game {
    top: -6.5vw;
  }

  .slide___3-Nqo {
    // padding-bottom: 33% !important;
    padding-bottom: 15% !important;
  }
}

.button-game-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 46.5%;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

.horizontalSlider___281Ls {
  height: 100%;
}

.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

.button-game-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 47%;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

.horizontalSlider___281Ls {
  height: 100%;
}

.settings-container h1 {
  font-size: 5vw;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  color: #FFF;
  -webkit-text-stroke: 2px #10D83A;
  text-shadow: 0px -2px 13px #10d83a;
  margin-bottom: 50px;
  padding-left: 3%;
  text-align: center;
}

.label-settings {
  font-weight: 300;
  color: #fff;
  font-size: 2vw;
}

.form-settings {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 5.5vw;
  color: #fff;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


.checkscore-green-bg-btn {
  background-color: rgba(0, 181, 55, 0.2);
}

.checkscore-red-bg-btn {
  background-color: rgba(219, 27, 23, 0.2);
}

.checkscore-green-bg-acc_summary {
  background-color: rgba(0, 181, 55, 0.63);
}

.checkscore-badge {
  width: 53px;
}

.checkscore-border-button-green {
  border-top: 3px solid #CFF3B1;
  border-left: 3px solid #CFF3B1;
  border-right: 3px solid #CFF3B1;
  border-radius: 0;
  // box-shadow: inset 0 0 12px 4px #00b537, 0 0 24px 6px #00b537;
  box-shadow: inset -1px 1px 22px 6px #00b537, 0 -11px 21px 2px #00b537;
}

.checkscore-border-button-red {
  border-top: 3px solid #FFBFBF;
  border-left: 3px solid #FFBFBF;
  border-right: 3px solid #FFBFBF;
  border-radius: 0;
  // box-shadow: inset 0 0 12px 4px #db1b17, 0 0 24px 6px #db1b17;
  box-shadow: inset -1px 1px 22px 6px #db1b17, 0 -11px 21px 2px #db1b17;
}

.checkscore-table-games-green {
  background: linear-gradient(to top, rgb(113, 21, 8, 0), rgb(0, 216, 65, 0.5));
  border: 3px solid #CFF3B1;
  border-radius: 0;
  box-shadow: inset 0 0 12px 4px #00b537, 0 0 24px 6px #00b537;
  background-color: rgba(13, 91, 38, 0.63);
  width: 104.5%;
  margin-left: -2.25%;
}

.checkscore-table-games-red {
  background: linear-gradient(to top, rgb(113, 21, 8, 0), rgb(219, 27, 23, 0.5));
  border: 3px solid #FFBFBF;
  border-radius: 0;
  box-shadow: inset 0 0 12px 4px #db1b17, 0 0 24px 6px #db1b17;
  background-color: rgba(219, 27, 23, 0.63);
  width: 104.5%;
  margin-left: -2.25%;
}

.checkscore-green-bg {
  background: url('../img/checkscore/Games_container_background_verde.webp') no-repeat scroll 50% 43%/112% 141%;
  width: 109%;
  margin-left: -4.4%;
}

.checkscore-red-bg {
  background: url('../img/checkscore/Games_container_background_rosu.webp') no-repeat scroll 50% 43%/113% 142%;
  width: 108%;
  margin-left: -3.9%;
}

.no-active-green {
  color: #FFBFBF;
  font-weight: 300;
}

.no-active-red {
  color: #CFF3B1;
  font-weight: 300;
}

.bb0 {
  border-bottom: 0px !important;
}

.be0 {
  border-right: 0px !important;
}

.bs0 {
  border-left: 0px !important;
}

.font-22 {
  font-size: 2.2vw;
}

.font-19 {
  font-size: 1.9vw;
}

.font-226 {
  font-size: 2.26vw !important;
}

.ms-25 {
  margin-left: 0.75rem;
}

.font-weight-300 {
  font-weight: 300;
}

.font-style-normal {
  font-style: normal;
}

.font-weight-400 {
  font-weight: 400;
}

.cff3b1 {
  color: #CFF3B1 !important;
}

.ffbfbf {
  color: #FFBFBF !important;
}

.padding-checkscore {
  padding-bottom: 15vw;
}

.a_single_a {
  background-size: 115% 100% !important;
  background-repeat: no-repeat !important;
  padding: 40px 30px 40px 30px;
  width: 80%;
  // min-height: 400px;
}

.a_single_a h3 {
  color: #FFF;
  font-size: 1.65vw;
  margin-top: -5.75%;
  margin-left: -4.7px;
}

.a_single_top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.a_single_a_color {
  background-size: 115% 77% !important;
}

.a_single_a_color_next {
  background-size: 100% 77% !important;
}

.ac_con {
  background-size: 100% 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 67px;
  height: 55px;
  width: 100%;
  color: #FFF;
  font-size: 2.25vw;
  font-weight: 700;
}

.p_color_next {
  margin-top: -51px;
}

.p_color_current {
  margin-top: -16px;
}

.p_color_previous {
  margin-top: 3px;
}

.p_color p {
  font-size: 1.43vw;
  font-weight: 700;
  color: #FFF;
}

.p_color a {
  background: url(../img/checkscore/banda_neon_Tableta_x2_v2.webp) no-repeat scroll 3% 70%/34% 80% !important;
  font-family: "sui-generis";
  width: 90%;
  background: transparent;
  font-size: 15.7px !important;
  font-style: italic;
  padding: 16px;
  color: #cff3b1;
  border: none;
  outline: none;
  margin-left: -15px;
  font-size: 1.5vw;
  font-weight: 400;
  max-width: 489px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-top: 24px;
}

.earn-more-tokens-text {
  font-size: 15.7px !important;
  color: #CFF3B1 !important;
  margin: 0;
  margin-top: 2px;
  margin-left: -19.5px !important;
}

.a_right {
  display: flex;
}

.ar_icon img {
  width: 75px;
}

.ar_text {
  font-size: 1.9vw;
  font-weight: 700;
  text-align: right;
  line-height: normal;
  font-style: italic;
}

.ar_text h2 {
  font-size: 2.85vw;
  font-weight: 600;
  font-style: italic;
  color: #FFF;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ar_text h2 img {
  width: 19px;
  margin-right: 3px;
  margin-top: -7px;
}

.game-rank-title {
  border-bottom: 1px solid #fff;
}

.game-rank-icon {
  width: 2.5vw;
}

.white-box {
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-self: center;
}

.white-box-click {
  border-bottom-color: transparent;
}

.games-table table > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}

.text-uppercase {
  text-transform: uppercase;
}

#root > div.tvScreen > div > div > div > div.row.pt3.pb3.text-white.checkscore-green-bg > div.games-table > table > tbody > tr:nth-child(1) > td:nth-child(1) > p > img {
  margin-left: 0.75rem;
}

#root > div.tvScreen > div > div > div > div.row.pt3.pb3.text-white.checkscore-green-bg > div.games-table > table > tbody > tr:nth-child(2) > td:nth-child(1) > p > img {
  margin-left: 0.75rem;
}

#root > div.tvScreen > div > div > div > div.row.pt3.pb3.text-white.checkscore-green-bg > div.games-table > table > tbody > tr:nth-child(3) > td:nth-child(1) > p > img {
  margin-left: 0.75rem;
}

#root > div.tvScreen > div > div > div > div.row.pt3.pb3.text-white.checkscore-green-bg > div.games-table > table > tbody > tr:nth-child(4) > td:nth-child(1) > p > img {
  margin-left: 0.75rem;
}

.nav.nav-tabs.games_in_tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 0;
}

.nav.nav-tabs.games_in_tab li {
  display: flex;
  flex: 1 1;
  text-align: center;
}

.nav.nav-tabs.games_in_tab li a {
  color: #FFF;
  border-left: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  border-top: 1px solid #FFF;
  padding: 13px 0;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root > div.tvScreen > div > div > div > div.pt3.pb3.checkscore-table-games-green > div.pb-3 > ul > li:nth-child(1) > a {
  border-left: none;
}

#root > div.tvScreen > div > div > div > div.pt3.pb3.checkscore-table-games-red > div.pb-3 > ul > li:nth-child(1) > a {
  border-left: none;
}

.nav.nav-tabs.games_in_tab a.active {
  border-bottom: none;
}

.checkscore-container {
  margin-top: 15vw;
}

.modal-how-to-play img {
  position: absolute;
  z-index: 1000;
  max-width: 2300px;
  top: 50%;
  left: 50%;
  transform: translate(-73.5%, -50%);
}

.button-back-scan {
  position: absolute;
  top: 5px;
  left: 62px;
  width: 15vw;
}

.button-back-register {
  top: -164px;
}

.button-back-lostBracelet {
  top: -152px;
}

.button-x-modal {
  left: 92% !important;
  top: -13.75% !important;
}

.isOver16 {
  appearance: none;
  width: 52px;
  min-width: 52px;
  height: 52px;
  cursor: pointer;
  padding: 15.5px;
}

.isOver16Label {
  margin: 0 !important;
  margin-left: 15px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.isOver16-bifat {
  background-image: url("../img/checkbox_bifat.webp")!important;
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 139%,  100%;
  color: #fff;
  background-color: transparent!important;
}
.isOver16-nebifat-verde{
  background-image: url("../img/checkbox_nebifat-min.webp")!important;
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 139%,  100%;
  color: #fff;
  background-color: transparent!important;
}
.isOver16-nebifat-gri{
  background-image: url("../img/newWaiver/checkbox_gray.png")!important;
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: 139%,  100%;
  color: #fff;
  background-color: transparent!important;
}
.relative{
  position: relative;
}

.border-right-waiver{
  height: calc(100% - 39px)
}
.border-left-waiver{
  height: calc(100% - 39px)
}
.border-bottom-waiver{
  bottom: 39px;
}
.blur-right-waiver{
 height: calc(100% - 36px);
}
.blur-left-waiver{
  height: calc(100% - 36px);
}
.blur-bottom-waiver{
bottom: 35px;
}
.modal-login{
  .modal-content{
    background-color: rgba(25, 126, 56, 1)!important;
  }
}